import Api from '@/services/Index';

// Student
const loginOrRegister = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/login-or-register', formData, {
        headers: {
            // remove headers
        }
    });
}

// Auth
const verify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfers/verify', formData);
}

const quotas = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/get-application-quotas',formData);
    
}

const getForm = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/get-application-form',formData);
    
}

const priceList = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/get-quota-price-list',formData);
}

const makeApplication = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/make-application',formData);
}

const makePayment = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/make-payment',formData);
}

const checkDate = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unihall/application/check-date');
}

const findBin = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/application/find-bin',formData);
}


export default {
    verify,
    quotas,
    priceList,
    makeApplication,
    getForm,
    loginOrRegister,
    makePayment,
    checkDate,
    findBin
};
